import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import Home from "./pages/home";
import { theme } from "./theme/theme";
import Header from "./components/header";
import Footer from "./components/footer";
import Research from "./components/research";
import Workflow from "./components/workflow";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./reducers/pitch";
import Test from "./pages/test";
import Email from "./pages/email";
import Upload from "./pages/upload";
import Result from "./pages/result";
import Processing from "./pages/processing";
import Waiting from "./pages/waiting";
import Privacy from "./pages/privacy";
import TermsAndConditions from "./pages/terms";
import { useEffect } from "react";
import { htmlScripts } from "./core/services/htmlScripts";

const store = createStore(reducer);

function App() {
  useEffect(() => {
    htmlScripts.init();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/workflow" element={<Workflow />} />
          <Route path="/about" element={<Research />} />
          <Route path="/test" element={<Test />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/email" element={<Email />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/processing" element={<Processing />} />
          <Route path="/waiting" element={<Waiting />} />
          <Route path="/result" element={<Result />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
